import React from "react"

import StructuredContentRenderer from "@src/components/StructuredContentRenderer"
import Page, { Footer, Header, HeaderContent } from "@src/components/Page"
import DefaultSeparator from "./_component_default_separator"

import data from "@src/fixtures/ProductStarRating.1.0.0.json"

const LAYOUTS = [
  "inline",
  "stacked",
  "big-number",
  "numeric",
  "small_numeric", // deprecated
  "small_numeric_inline",
]

const ProductStarRatingPage = () => {
  const title = "Product Star Rating"
  const breadcrumb = {
    page: {
      title,
    },
    slug: "SMALL_BUSINESS",
  }
  return (
    <Page
      title={title}
      header={<Header />}
      headerContent={<HeaderContent title={title} breadcrumb={breadcrumb} />}
      footer={<Footer />}
    >
      <DefaultSeparator />
      <StructuredContentRenderer>{data}</StructuredContentRenderer>
      <DefaultSeparator />
      {LAYOUTS.map(layout => {
        const structuredContentData = {
          type: "ProductStarRating",
          version: "1.0.0",
          props: {
            rating: null,
            showNotYetRated: true,
            layout,
          },
        }

        return (
          <section key={layout}>
            <h2>{layout}</h2>
            <StructuredContentRenderer>
              {structuredContentData}
            </StructuredContentRenderer>
          </section>
        )
      })}
      <DefaultSeparator />
      {LAYOUTS.map(layout => {
        const structuredContentData = {
          type: "ProductStarRating",
          version: "1.0.0",
          props: {
            rating: 3.5,
            layout,
          },
        }

        return (
          <section key={layout}>
            <h2>{layout}</h2>
            <StructuredContentRenderer>
              {structuredContentData}
            </StructuredContentRenderer>
          </section>
        )
      })}
      <DefaultSeparator />
    </Page>
  )
}

export default ProductStarRatingPage
